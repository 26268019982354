import { IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonMenuButton, IonPage, IonRow, IonTextarea, IonTitle, IonToolbar } from "@ionic/react"
import NavigationMenu from "../components/NavigationMenu"

const AddDishPage: React.FC = () => {

    return (
        <>
            <NavigationMenu />
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton slot="start" />
                        </IonButtons>
                        <IonTitle>Add Dish</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent id="main" style={{ padding: "5%" }}>

                    {/* <IonLabel>
                        Upload Photo
                    </IonLabel> */}
                    <IonItem>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonButton>Take Photo</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton>Upload from Photos</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonItem>

                    <IonTextarea label="Description" labelPlacement="floating"></IonTextarea>
                    <IonTextarea label="Ingredients" labelPlacement="floating"></IonTextarea>

                    <IonInput label="Cooking Time (minutes)" type="number" labelPlacement="floating"></IonInput>

                    <IonList>
                        <IonListHeader>Utensils</IonListHeader>
                        <IonItem lines="none"><IonCheckbox>Fork</IonCheckbox></IonItem>
                        <IonItem lines="none"><IonCheckbox>Spoon</IonCheckbox></IonItem>

                        <IonItem lines="none"><IonCheckbox>Knife</IonCheckbox></IonItem>

                        <IonItem lines="none"><IonCheckbox>Bowl</IonCheckbox></IonItem>

                    </IonList>
                    <IonItem lines="none">
                        <IonButton>Save</IonButton>
                    </IonItem>
                </IonContent>
            </IonPage>
        </>
    )
}

export default AddDishPage;