import { IonButton, IonButtons, IonCheckbox, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonMenuButton, IonNote, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import NavigationMenu from "../components/NavigationMenu"

const FoodSurveyPage: React.FC = () => {

    return (
        <>
            <NavigationMenu />
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton slot="start" />
                        </IonButtons>
                        <IonTitle>Food Survey</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent id="main" >
                    <IonLabel className="ion-margin">
                        Complete the following survey so we can provide you with better recipes!
                    </IonLabel>
                    <IonList className="ion-margin">
                        <IonListHeader>What kind of foods do you enjoy?</IonListHeader>
                        <IonItem lines="none"><IonCheckbox>Food 1</IonCheckbox></IonItem>
                        <IonItem lines="none"><IonCheckbox>Food 2</IonCheckbox></IonItem>
                        <IonItem lines="none"><IonCheckbox>Food 3</IonCheckbox></IonItem>
                        <IonItem lines="none"><IonCheckbox>Food 4</IonCheckbox></IonItem>
                    </IonList>

                    <IonList className="ion-margin">
                        <IonListHeader>From what countries?</IonListHeader>
                        <IonItem lines="none"><IonCheckbox>Country 1</IonCheckbox></IonItem>
                        <IonItem lines="none"><IonCheckbox>Country 2</IonCheckbox></IonItem>
                        <IonItem lines="none"><IonCheckbox>Country 3</IonCheckbox></IonItem>
                        <IonItem lines="none"><IonCheckbox>Country 4</IonCheckbox></IonItem>
                    </IonList>

                    <IonList className="ion-margin">
                        <IonListHeader>Favorite flavors?</IonListHeader>
                        <IonItem lines="none"><IonCheckbox>Spicy</IonCheckbox></IonItem>
                        <IonItem lines="none"><IonCheckbox>Sweet</IonCheckbox></IonItem>
                        <IonItem lines="none"><IonCheckbox>Option 1</IonCheckbox></IonItem>
                        <IonItem lines="none"><IonCheckbox>Option 2</IonCheckbox></IonItem>
                    </IonList>

                </IonContent>
                <IonFooter>
                    <IonButton expand="full" href="/home">Save</IonButton>
                </IonFooter>
            </IonPage>
        </>
    )
}

export default FoodSurveyPage;