import { IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu } from "@ionic/react"
import { add, earth, list, newspaper } from "ionicons/icons";
import { useHistory } from "react-router";

const NavigationMenu: React.FC = () => {

    const history = useHistory()

    return (
        <IonMenu contentId="main" type="overlay">
            <IonList>
                <IonListHeader>Main Menu</IonListHeader>
                <IonItem >
                    <IonButtons slot="start" onClick={() => history.push("/add-dish")}>
                        <IonButton href="/add-dish">
                            <IonIcon src={add} size="large"></IonIcon>
                        </IonButton>
                    </IonButtons>
                    <IonLabel>Add A Dish</IonLabel>
                </IonItem>
                <IonItem >
                    <IonButtons slot="start" onClick={() => history.push("/survey")}>
                        <IonButton >
                            <IonIcon src={newspaper} size="large"></IonIcon>
                        </IonButton>
                    </IonButtons>
                    <IonLabel>Survey</IonLabel>
                </IonItem>
                <IonItem>
                    <IonButtons slot="start" onClick={() => history.push("/foreign")}>
                        <IonButton href="/foreign">
                            <IonIcon src={earth} size="large"></IonIcon>
                        </IonButton>
                    </IonButtons>
                    <IonLabel>Foreign</IonLabel>
                </IonItem>
                <IonItem>
                    <IonButtons slot="start" onClick={() => history.push("/shopping")}>
                        <IonButton href="/shopping">
                            <IonIcon src={list} size="large"></IonIcon>
                        </IonButton>
                    </IonButtons>
                    <IonLabel>Shopping List</IonLabel>
                </IonItem>
            </IonList>
        </IonMenu>
    )
}

export default NavigationMenu;