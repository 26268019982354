import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonItem, IonList, IonMenuButton, IonPage, IonSearchbar, IonTitle, IonToolbar } from "@ionic/react"
import NavigationMenu from "../components/NavigationMenu"

const HomePage: React.FC = () => {
    return (
        <>
            <NavigationMenu />
            <IonPage id='main'>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton autoHide={false}></IonMenuButton>
                        </IonButtons>
                        <IonTitle>Home </IonTitle>
                    </IonToolbar>
                    <IonSearchbar></IonSearchbar>
                </IonHeader>
                <IonContent>
                    <IonList>
                        <IonCard>
                            <img src="./assets/meal-1.jpg" />
                            <IonCardHeader>
                                <IonCardTitle>Meal of the Day</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonCardSubtitle>Rice Bun Entree</IonCardSubtitle>
                                {/* 
                            <IonItem>
                                <IonButton size="default">View</IonButton>
                                <IonButton size="default">Save</IonButton>
                            </IonItem> */}
                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <img src="./assets/meal-2.jpg" />
                            <IonCardHeader>
                                <IonCardTitle>Recommendation</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonCardSubtitle>Grilled Fish Entree</IonCardSubtitle>

                                {/* <IonItem>
                                <IonButton size="default">View</IonButton>
                                <IonButton size="default">Save</IonButton>
                            </IonItem> */}
                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <img src="./assets/meal-3.jpg" />
                            <IonCardHeader>
                                <IonCardTitle>Recommendation</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonCardSubtitle>Baked Chicken with Mushroom Sauce</IonCardSubtitle>

                                {/* <IonItem>
                                <IonButton size="default">View</IonButton>
                                <IonButton size="default">Save</IonButton>
                            </IonItem> */}
                            </IonCardContent>
                        </IonCard>
                    </IonList>
                </IonContent>
            </IonPage>
        </>
    )
}

export default HomePage;