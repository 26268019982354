import { IonButton, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonPage } from "@ionic/react"
import { logoApple, logoGoogle } from "ionicons/icons";
import { useHistory } from "react-router";

const LoginPage: React.FC = () => {

    const history = useHistory()
    return (
        <IonPage>
            <IonHeader>

            </IonHeader>

            <IonContent>

                <h1>Chewie App</h1>

                <IonItem>
                    {/* <IonLabel>Username</IonLabel> */}
                    <IonInput label="Username" labelPlacement="floating"></IonInput>
                </IonItem>
                <IonItem>
                    {/* <IonLabel>Password</IonLabel> */}
                    <IonInput label="Password" type="password" labelPlacement="floating"></IonInput>
                </IonItem>

                <IonButton>Sign In</IonButton>

                <IonItemDivider></IonItemDivider>

                <IonItem lines="none">
                    <IonButton color="danger" onClick={() => history.push("/home")}>
                        <IonIcon slot="start" icon={logoGoogle}></IonIcon>
                        Login with Gmail
                    </IonButton>
                </IonItem>

                <IonItem lines="none">
                    <IonButton color="light" onClick={() => history.push("/home")}>
                        <IonIcon slot="start" icon={logoApple}></IonIcon>
                        Login with iCloud
                    </IonButton>
                </IonItem>

            </IonContent>
        </IonPage>
    )
}

export default LoginPage;